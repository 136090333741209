<template>
    <el-dialog
        :before-close="closeDialog"
        :show-close="false"
        :visible.sync="dialogVisible"
        center
        width="7.9in">
        <span slot="title" class="title">打印预览</span>
        <div class="dialog-container">
            <div id="printTest">
                <div class="apply-title">支持内容明细表</div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">项目编号：</span>
                    <span class="approval-detail-content">{{ detail.project.pro_no.join(',') }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">所属学会：</span>
                    <span class="approval-detail-content">{{ detail.society }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('会议名称', detail.project.cid) }}：</span>
                    <span class="approval-detail-content">{{ detail.project.name }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('会议日期', detail.project.cid) }}：</span>
                    <span class="approval-detail-content">{{ detail.time }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('大会主席', detail.project.cid) }}：</span>
                    <span class="approval-detail-content">{{ detail.project.chairman }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">企业名称：</span>
                    <span class="approval-detail-content">{{ detail.corporate_name }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">企业备注：</span>
                    <span class="approval-detail-content">{{ detail.corporate_name_remarks }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">联系人：</span>
                    <span class="approval-detail-content">{{ detail.contacts }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">协议金额：</span>
                    <span class="approval-detail-content">{{ parseFloat(Number(detail.amount).toFixed(6)) }}万元</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">专项金额：</span>
                    <span class="approval-detail-content">{{ parseFloat(Number(detail.self_amount).toFixed(6))}}万元</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('会议金额', detail.project.cid) }}：</span>
                    <span class="approval-detail-content">{{ parseFloat(Number(detail.meeting_decimal).toFixed(6)) }}万元</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip red">备注：</span>
                    <span class="approval-detail-content red">{{ detail.remark }}</span>
                </div>
                <div class="approval-detail-container">
                    <div class="approval-detail-title">
                        <!--                        <span class="approval-detail-tip">签名：</span>-->
                        <div class="approval-detail-content">
                            <el-image :src="detail.sign" style="width: 100%; height: auto"></el-image>
                        </div>
                    </div>
                </div>
                <div class="approval-list-container">
                    <div class="approval-list-tip">审批流程</div>

                    <div class="approval-list">
                        <div class="name-container active">
                            <span>{{ detail.true_name | sub_name }}<i class="el-icon-success"></i></span>
                            <div class="info-container">
                                <div class="info-top">
                                    <div class="info-content">{{ detail.true_name }}</div>
                                    <div class="info-time">{{ detail.add_time | formatTime }}</div>
                                </div>
                                <div class="info-desc">发起上游协议用印申请</div>
                            </div>
                        </div>
                        <div v-if="parseFloat(Number(detail.self_amount).toFixed(6)) !== 0" class="name-container active">
                            <span>签字<i :class="detail.sign ? 'el-icon-success' : 'el-icon-remove'"></i></span>
                            <div class="info-container">
                                <div class="info-top">
                                    <div class="info-content">企业负责人</div>
                                    <div v-if="detail.allSp[0].sp_time" class="info-time">
                                        {{ detail.allSp[0].sp_time | formatTime }}
                                    </div>
                                </div>
                                <div class="info-desc">
                                    {{ detail.sign ? "已签字" : "签字中" }}
                                </div>
                            </div>
                        </div>
                        <div v-if="detail.state != 6" class="name-container active">
                        <span>{{detail.shenpi[0].true_name | sub_name}}
                          <i :class="detail.shenpi[0].state === 0 || detail.shenpi[0].state === 3
                                ? 'el-icon-remove'
                                : detail.shenpi[0].state === 1
                                ? 'el-icon-success'
                                : 'el-icon-error' "></i>
                        </span>
                            <div class="info-container">
                                <div class="info-top">
                                    <div class="info-content">
                                        {{ detail.shenpi[0].true_name
                                        }}<i v-if="detail.shenpi[0].state != 0"
                                    >({{ detail.shenpi[0].state | sp_state(0) }})</i
                                    >
                                    </div>
                                    <div v-if="detail.shenpi[0].sp_time" class="info-time">
                                        {{ detail.shenpi[0].sp_time | formatTime }}
                                    </div>
                                </div>
                                <div v-if="detail.shenpi[0].state === 0" class="info-desc">
                                    {{ iself(detail.shenpi[0]) ? "我（" : ""
                                    }}{{ detail.shenpi[0].state | sp_state(0)
                                    }}{{ iself(detail.shenpi[0]) ? "）" : "" }}
                                </div>
                                <div v-if="detail.shenpi[0].state !== 0" class="info-desc">
                                    {{ detail.shenpi[0].sp_remark }}
                                </div>
                            </div>
                        </div>
                        <div v-if="detail.shenpi[0].state == 1 && detail.shenpi.length > 3" class="name-container active">
            <span
            >{{
                    detail.shenpi[1].true_name | sub_name
                }}
              <i
                  :class="
                  detail.shenpi[1].state === 0 || detail.shenpi[1].state === 3
                    ? 'el-icon-remove'
                    : detail.shenpi[1].state === 1
                    ? 'el-icon-success'
                    : 'el-icon-error'
                "
              ></i>
            </span>
                            <div class="info-container">
                                <div class="info-top">
                                    <div class="info-content">
                                        {{ detail.shenpi[1].true_name
                                        }}<i v-if="detail.shenpi[1].state != 0"
                                    >({{ detail.shenpi[1].state | sp_state(1) }})</i
                                    >
                                    </div>
                                    <div v-if="detail.shenpi[1].sp_time" class="info-time">
                                        {{ detail.shenpi[1].sp_time | formatTime }}
                                    </div>
                                </div>
                                <div v-if="detail.shenpi[1].state === 0" class="info-desc">
                                    {{ iself(detail.shenpi[1]) ? "我（" : ""
                                    }}{{ detail.shenpi[1].state | sp_state(1)
                                    }}{{ iself(detail.shenpi[1]) ? "）" : "" }}
                                </div>
                                <div v-if="detail.shenpi[1].state !== 0" class="info-desc">
                                    {{ detail.shenpi[1].sp_remark }}
                                </div>
                            </div>
                        </div>
                        <div v-if="(detail.shenpi[detail.shenpi.length -3].state == 1 && detail.confirm == 0 )|| detail.confirm ==1"
                             class="name-container active">
                        <span>
                          {{detail.true_name | sub_name}}
                          <i :class="detail.confirm === 0 ? 'el-icon-remove' : 'el-icon-success'"></i>
                        </span>
                            <div class="info-container">
                                <div class="info-top">
                                    <div class="info-content">{{ detail.true_name }}</div>
                                    <div v-if="detail.shenpi.length > 3">
                                        <div v-if="detail.allSp[parseFloat(Number(detail.self_amount).toFixed(6)) !== 0 ? 3 : 2].sp_time"
                                             class="info-time">
                                            {{ detail.allSp[parseFloat(Number(detail.self_amount).toFixed(6)) !== 0 ? 3 : 2].sp_time | formatTime }}
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div v-if="detail.allSp[parseFloat(Number(detail.self_amount).toFixed(6)) !== 0 ? 2 : 1].sp_time"
                                             class="info-time">
                                            {{ detail.allSp[parseFloat(Number(detail.self_amount).toFixed(6)) !== 0 ? 2 : 1].sp_time | formatTime
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div class="info-desc">
                                    {{detail.confirm === 0 ? "确认中" : "确认知晓"}}
                                </div>
                            </div>
                        </div>
                        <div v-if="detail.confirm ==1">
                            <div v-for="item in detail.shenpi.slice(detail.shenpi.length > 3 ? 2 : 1,detail.shenpi.length > 3 ? 3 : 2)"
                                 :key="item.id"
                                 class="name-container active">
                            <span>{{item.true_name | sub_name }}
                              <i :class="item.state === 0? 'el-icon-remove': item.state === 1
                              ? 'el-icon-success' : 'el-icon-error'"></i>
                            </span>
                                <div class="info-container">
                                    <div class="info-top">
                                        <div class="info-content">
                                            {{ item.true_name}}
                                        </div>
                                        <div v-if="item.sp_time" class="info-time">
                                            {{ item.sp_time | formatTime }}
                                        </div>
                                    </div>
                                    <div class="info-desc">
                                        {{item.state == 1 ? "已用印" :
                                        (item.state == 2 ? "已驳回（" + item.sp_remark + "）" : "用印确认中")}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--<div-->
                        <!--class="name-container active"-->
                        <!--v-if="(detail.shenpi[1].state === 1) && detail.confirm ==1"-->
                        <!--&gt;-->
                        <!--<span>-->
                        <!--{{-->
                        <!--detail.true_name.substr(-->
                        <!--detail.true_name.length - 2,-->
                        <!--detail.true_name.length-->
                        <!--)-->
                        <!--}}-->
                        <!--<i-->
                        <!--:class="-->
                        <!--detail.state !== 2 && detail.state !== 4-->
                        <!--? 'el-icon-remove'-->
                        <!--: 'el-icon-success'-->
                        <!--"-->
                        <!--&gt;</i>-->
                        <!--</span>-->
                        <!--<div class="info-container">-->
                        <!--<div class="info-top">-->
                        <!--<div class="info-content">{{ detail.true_name }}</div>-->
                        <!--&lt;!&ndash;<div class="info-time">{{detail.sp_time | formatTime}}</div>&ndash;&gt;-->
                        <!--</div>-->
                        <!--<div class="info-desc">-->
                        <!--{{-->
                        <!--detail.seal_files.length > 0-->
                        <!--? "已上传盖章协议"-->
                        <!--: detail.state !== 2 && detail.state !== 4-->
                        <!--? "上传盖章协议中"-->
                        <!--: "未上传盖章协议"-->
                        <!--}}-->
                        <!--</div>-->
                        <!--<div-->
                        <!--class="info-desc"-->
                        <!--v-if="detail.seal_files && detail.seal_files.length > 0"-->
                        <!--&gt;-->
                        <!--<div class="upfile-list">-->
                        <!--<li-->
                        <!--class="flex-center between-box"-->
                        <!--v-for="(file, index) in detail.seal_files"-->
                        <!--:key="index"-->
                        <!--&gt;-->
                        <!--<a-->
                        <!--class="ovHide"-->
                        <!--:href="file.filePath | filePath"-->
                        <!--target="_blank"-->
                        <!--&gt;{{ file.fileName }}</a-->
                        <!--&gt;-->
                        <!--</li>-->
                        <!--</div>-->
                        <!--</div>-->
                        <!--<div-->
                        <!--class="info-desc"-->
                        <!--v-if="-->
                        <!--member_type == 1 &&-->
                        <!--detail.seal_files.length === 0 &&-->
                        <!--detail.state === 1-->
                        <!--"-->
                        <!--&gt;-->
                        <!--<div v-if="files.length > 0" class="upfile-list">-->
                        <!--<ul v-for="(file, index) in files" :key="index">-->
                        <!--<li class="flex-center between-box">-->
                        <!--<a-->
                        <!--class="ovHide"-->
                        <!--:href="file.filePath | filePath"-->
                        <!--target="_blank"-->
                        <!--&gt;{{ file.fileName }}</a-->
                        <!--&gt;-->
                        <!--<i class="el-icon-delete" @click="closeFile(index)"></i>-->
                        <!--</li>-->
                        <!--</ul>-->
                        <!--</div>-->
                        <!--&lt;!&ndash;<div class="info-file">{{fileName}}</div>&ndash;&gt;-->
                        <!--<el-upload-->
                        <!--action="http://upload-z2.qiniup.com/"-->
                        <!--:show-file-list="false"-->
                        <!--:multiple="true"-->
                        <!--:data="QiniuData"-->
                        <!--:on-error="uploadError"-->
                        <!--:on-success="uploadSuccess"-->
                        <!--:before-upload="beforeAvatarUpload"-->
                        <!--&gt;-->
                        <!--<el-button class="info-file-up"-->
                        <!--&gt;<i class="el-icon-upload info-file-up-logo"></i-->
                        <!--&gt;点击上传文件-->
                        <!--</el-button-->
                        <!--&gt;-->
                        <!--&lt;!&ndash;<div slot="tip" class="el-upload__tip">只能上传PDF文件</div>&ndash;&gt;-->
                        <!--</el-upload>-->
                        <!--</div>-->
                        <!--</div>-->
                        <!--</div>-->
                        <div v-if="detail.state == 2 && detail.confirm ==1 || detail.state == 4"
                             class="name-container">
                        <span>{{detail.shenpi[detail.shenpi.length - 1].true_name | sub_name }}
                          <i :class="
                              detail.state == 3 ||
                              detail.shenpi[detail.shenpi.length -1].state === 0 ||
                              detail.shenpi[detail.shenpi.length -1].state === 3
                                ? 'el-icon-remove'
                                : detail.shenpi[detail.shenpi.length -1].state === 1
                                ? 'el-icon-success'
                                : 'el-icon-error'
                            "></i>
                        </span>
                            <div class="info-container">
                                <div class="info-top">
                                    <div class="info-content">
                                        {{ detail.shenpi[detail.shenpi.length - 1].true_name
                                        }}<i v-if="detail.shenpi[detail.shenpi.length -1].state != 0"
                                    >({{ detail.shenpi[detail.shenpi.length - 1].state | sp_state(detail.shenpi.length - 1)
                                        }})</i
                                    >
                                    </div>
                                    <div v-if="detail.shenpi[detail.shenpi.length -1].sp_time" class="info-time">
                                        {{ detail.shenpi[detail.shenpi.length - 1].sp_time | formatTime }}
                                    </div>
                                </div>
                                <div v-if="detail.shenpi[detail.shenpi.length -1].state === 0" class="info-desc">
                                    {{ iself(detail.shenpi[detail.shenpi.length - 1]) ? "我（" : ""
                                    }}{{ detail.shenpi[detail.shenpi.length - 1].state | sp_state(detail.shenpi.length - 1)
                                    }}{{ iself(detail.shenpi[detail.shenpi.length - 1]) ? "）" : "" }}
                                </div>
                                <div v-if="detail.shenpi[detail.shenpi.length -1].state !== 0" class="info-desc">
                                    {{ detail.shenpi[detail.shenpi.length - 1].sp_remark }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">取消</el-button>
            <el-button v-print="'#printTest'" class="printing" type="primary">打印</el-button>
      </span>
    </el-dialog>
</template>

<script>
import {sub_name, webUrl, ZHDate} from "../../../tools";

export default {
    name: "sponsor-print",
    props: {
        dialogVisible: {
            type: Boolean,
            default: false
        },
        detail: { //详情
            type: Object,
        },
    },
    methods: {
        iself: function (item) {
            return item.user_id === this.$store.state.user.mid;
        },
        closeDialog() {
            this.$emit('watchState', false)
        }
    },
    filters: {
        filePath(url) {
            return webUrl(url)
        },
        state(type, detail) {
            switch (Number(type)) {
                case 0:
                    return "审批中";
                case 1:
                    return "已用印" + (detail.is_replied === 1 ? "" : "（协议未回）");
                case 2:
                    return "协议存档中";
                case 3:
                    return "已驳回";
                case 4:
                    return "已完成";
                case 6:
                    return "企业负责人签字中";
                default:
                    return "已撤销";
            }
        },
        sp_state(type, position) {
            switch (Number(type)) {
                case 0:
                    return position === 3 ? "存档中" : "审批中";
                case 3:
                    return position === 3 ? "未存档" : "未审批";
                case 1:
                    return position === 3 ? "已完成" : "已审核";
                default:
                    return "已驳回";
            }
        },
        formatTime(time) {
            return ZHDate(new Date(time * 1000), 6)
        },
        sub_name(str) {
            return sub_name(str)
        },
    },
}
</script>

<style lang="scss" scoped>
.title {
    font-size: 16px;
    color: #333333;
    text-align: center;
    font-weight: bold;
}
.dialog-container {
    height: 600px;
    overflow-y: scroll;
    border: 1px solid #e1e1e1;
    &::-webkit-scrollbar {
        display: none;
    }
}
#printTest {
    background: #FFFFFF;
    padding: 20px;
    border: 1px solid #e1e1e1;
}

.apply-title {
    font-size: 22px;
    color: #333333;
    text-align: center;
    font-weight: bold;
    margin: 20px 0 50px;
}

.approval-detail-title {
    display: flex;
    flex-direction: row;
    font-size: 18px;
    color: #999999;
    width: 100%;
    padding: 10px 0;
    .approval-detail-tip {
        flex: 1;
        flex-shrink: 0;

        &.red {
            color: red;
        }
    }
    .approval-detail-tip-length {
        flex-shrink: 0;
        &.red {
            color: red;
        }
    }
    .approval-detail-content {
        flex: 4;
        flex-shrink: 1;
        color: #333333;
        &.red {
            color: red;
        }
    }
}
.approval-list-container {
    display: flex;
    flex-direction: column;
    padding: 20px 30px;

    .approval-list {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        .name-container {
            position: relative;
            display: flex;
            padding-bottom: 50px;
            flex-direction: row;

            &.active {
                &:before {
                    position: absolute;
                    background: #d3d4d6;
                    width: 1px;
                    top: 0px;
                    bottom: 0px;
                    left: 28px;
                    content: "";
                }
            }

            .info-container {
                flex-grow: 1;
                margin-left: 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .info-top {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .info-content {
                        font-size: 20px;
                        color: #333333;

                        i {
                            font-size: 14px;
                        }
                    }

                    .info-time {
                        font-size: 16px;
                        color: #999999;
                    }
                }

                .info-desc {
                    margin-top: 2px;
                    font-size: 16px;
                    color: #999999;
                }

                .copy-members {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    margin-top: 20px;

                    .copy-members-name {
                        margin-right: 10px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                }
            }

            span {
                display: flex;
                align-items: center;
                justify-content: space-around;
                width: 56px;
                height: 56px;
                border-radius: 56px;
                border: 1px solid #3b77e7;
                text-align: center;
                /*line-height: 24px;*/
                font-size: 16px;
                color: #ffffff;
                background-color: #3b77e7;
                position: relative;

                i {
                    position: absolute;
                    bottom: -5px;
                    right: -5px;
                    font-size: 18px;
                    width: 18px;
                    height: 18px;
                    overflow: hidden;

                    &.el-icon-remove {
                        color: #ee9900;
                    }

                    &.el-icon-success {
                        color: #13ce66;
                    }

                    &.el-icon-error {
                        color: #ff0000;
                    }
                }
            }
        }
    }

    .approval-list-tip {
        font-size: 18px;
        color: #333333;
        flex-shrink: 1;
        margin-top: 12px;
    }
}
</style>