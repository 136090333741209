<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：总览 > <b>上游协议</b></span>
            <div class="flex-center" @click="backPage">
                <i class="iconfont iconjiantou-copy-copy"></i>返回
            </div>
        </div>
        <div class="approval-detail-info">
            <el-button v-if="detail.sign" @click="printDialogVisible = true" class="printing" type="primary">打印
            </el-button>
            <div class="flex">
                <div class="approval-detail-container flex-sub">
                    <div class="name-container">
                        <span>{{ detail.true_name | sub_name }}</span>
                        <div class="state-container">
                            <div class="content">{{ detail.true_name }}<i
                                :class="{red:detail.revoke_type_state === 1 || detail.shenpi_num > 1}">(<i
                                v-if="detail.revoke_type_state === 1">撤销后，</i>第{{ detail.shenpi_num }}次提交)</i></div>
                            <div v-if="detail.shenpi[detail.shenpi.length -3].state == 1 && detail.confirm === 0"
                                 class="state">
                                等待{{ detail.true_name }}用印
                            </div>
                            <div v-else-if="detail.state == 1 && detail.shenpi[detail.shenpi.length -3].state == 1 && detail.shenpi[detail.shenpi.length -1].state == 0  && detail.confirm === 1"
                                 class="state">
                                等待用印登记
                            </div>
                            <div v-else :class="{red: detail.is_replied !== 1 && detail.state === 4 }" class="state">
                                {{ detail.state | state(detail) }}<i v-if="detail.if_void == 1">(已作废：{{ detail.void_desc
                                }})</i>
                            </div>
                        </div>
                    </div>
                    <div class="approval-detail-title">
                        <span class="approval-detail-tip">审批编号：</span>
                        <span class="approval-detail-content">{{ detail.shenpi_no }}</span>
                    </div>
                    <div class="approval-detail-title">
                        <span class="approval-detail-tip">项目编号：</span>
                        <span class="approval-detail-content">{{ detail.project.pro_no.join(',') }}</span>
                    </div>
                    <div class="approval-detail-title">
                        <span class="approval-detail-tip">所属学会：</span>
                        <span class="approval-detail-content">{{ detail.society }}</span>
                    </div>
                    <div class="approval-detail-title">
                        <span class="approval-detail-tip">{{ $getTitleByType('会议名称', detail.project.cid) }}：</span>
                        <span class="approval-detail-content">{{ detail.project.name }}</span>
                    </div>
                    <div class="approval-detail-title">
                        <span class="approval-detail-tip">{{ $getTitleByType('会议日期', detail.project.cid) }}：</span>
                        <span class="approval-detail-content">{{ detail.time }}</span>
                    </div>
                    <div class="approval-detail-title">
                        <span class="approval-detail-tip">{{ $getTitleByType('会议', detail.project.cid) }}形式：</span>
                        <span class="approval-detail-content">{{ detail.meet_form_str }}</span>
                    </div>
                    <div class="approval-detail-title">
                        <span class="approval-detail-tip">{{ $getTitleByType('大会主席', detail.project.cid) }}：</span>
                        <span class="approval-detail-content">{{ detail.project.chairman }}</span>
                    </div>
                    <project-info :cid="detail.project.cid" :info="detail.project_attach"></project-info>
                    <div class="approval-detail-title">
                        <span class="approval-detail-tip">招商申请日期：</span>
                        <span class="approval-detail-content">{{ detail.zhaoshang.name }}</span>
                    </div>
                    <div class="approval-detail-title">
                        <span class="approval-detail-tip">支持类别：</span>
                        <span class="approval-detail-content">{{ detail.categories === 1 ? "赞助" : "捐赠" }}</span>
                    </div>
                    <div class="approval-detail-title">
                        <span class="approval-detail-tip">企业名称：</span>
                        <span class="approval-detail-content">{{ detail.corporate_name }}</span>
                    </div>
                    <div class="approval-detail-title">
                        <span class="approval-detail-tip">企业备注：</span>
                        <span class="approval-detail-content">{{ detail.corporate_name_remarks }}</span>
                    </div>
                    <div class="approval-detail-title">
                        <span class="approval-detail-tip">联系人：</span>
                        <span class="approval-detail-content">{{ detail.contacts }}</span>
                    </div>
                    <div class="approval-detail-title">
                        <span class="approval-detail-tip">协议金额：</span>
                        <span class="approval-detail-content">{{ parseFloat(Number(detail.amount).toFixed(6))
                            }}万元</span>
                    </div>
                    <div class="approval-detail-title">
                        <span class="approval-detail-tip">专项金额：</span>
                        <span class="approval-detail-content">{{ parseFloat(Number(detail.self_amount).toFixed(6)) }}万元</span>
                    </div>
                    <div class="approval-detail-title">
                        <span class="approval-detail-tip">{{ $getTitleByType('会议金额', detail.project.cid) }}：</span>
                        <span class="approval-detail-content">{{ parseFloat(Number(detail.meeting_decimal).toFixed(6))
                            }}万元</span>
                    </div>
                    <div class="approval-detail-title">
                        <span class="approval-detail-tip">赞助回报项目：</span>
                        <span class="approval-detail-content">{{ detail.zan_zhu_hui_bao }}</span>
                    </div>
                    <div class="approval-detail-title">
                        <span class="approval-detail-tip">加盖何种印章：</span>
                        <span class="approval-detail-content">合同章</span>
                    </div>
                    <div class="approval-detail-title">
                        <span class="approval-detail-tip-length">是否已核对协议金额与招商函匹配：</span>
                        <span class="approval-detail-content">已核对</span>
                    </div>
                    <div class="approval-detail-title">
                        <span class="approval-detail-tip">协议状态：</span>
                        <span class="approval-detail-content">{{ detail.is_replied === 1 ? "已回" : "未回" }}</span>
                    </div>
                    <div class="approval-detail-title">
                        <span class="approval-detail-tip red">备注：</span>
                        <span class="approval-detail-content red">{{ detail.remark }}</span>
                    </div>

                    <div class="approval-detail-title">
                        <span class="approval-detail-tip">附件：</span>
                        <div class="approval-detail-file">
                            <a v-for="(file, index) in detail.files" :key="index" :href="file.filePath | filePath"
                               target="_blank">{{ file.fileName }}</a>
                        </div>
                    </div>
                    <div v-if="detail.qr_code" class="approval-detail-title">
                        <span class="approval-detail-tip">二维码：</span>
                        <div class="approval-detail-content">
                            <el-image :src="detail.qr_code" style="width: 150px; height: 150px"></el-image>
                        </div>
                    </div>

                    <div v-if="detail.sign" class="approval-detail-title">
                        <span class="approval-detail-tip">签名：</span>
                        <div class="approval-detail-content">
                            <el-image :src="detail.sign" style="width: 550px; height: auto"></el-image>
                        </div>
                    </div>
                    <div v-if="detail.sign_desc" class="approval-detail-title">
                        <span class="approval-detail-tip red">备注：</span>
                        <span class="approval-detail-content red">{{ detail.sign_desc }}</span>
                    </div>
                </div>
                <div class="company-container" v-if="isZGH && speLists.teshu.length + speLists.zhaoshang.length">
                    <div class="flex align-center">
                        <span class="tab" :class="{active:speTab ===0}" @click="speTab = 0">特殊审批</span>
                        <span class="tab margin-left-sm" :class="{active:speTab ===1}" @click="speTab = 1">招商资料</span>
                    </div>
                    <template v-if="speTab ===0">
                        <div class="info-item flex flex-direction" v-for="(item,index) in speLists.teshu" :key="index">
                            <div class="name">{{item.add_time}}</div>
                            <div class="remark margin-top-xs">{{item.remark}}</div>
                            <div class="file margin-top-xs">
                                <a v-for="(file, index) in item.files" :key="index" :href="file.filePath | filePath"
                                   target="_blank">{{ file.fileName }}</a>
                            </div>
                        </div>
                        <el-empty description="暂无数据" image-size="120" class="self-center margin-top-sm" v-if="!speLists.teshu.length"></el-empty>
                    </template>
                    <template v-if="speTab ===1">
                        <div class="info-item flex flex-direction" v-for="(item,index) in speLists.zhaoshang" :key="index">
                            <div class="name">{{item.add_time}}</div>
                            <div class="remark margin-top-xs">{{item.remark}}</div>
                            <div class="file margin-top-xs">
                                <a v-for="(file, index) in item.files" :key="index" :href="file.filePath | filePath"
                                   target="_blank">{{ file.fileName }}</a>
                            </div>
                        </div>
                        <el-empty description="暂无数据" image-size="120" class="self-center margin-top-sm"  v-if="!speLists.zhaoshang.length"></el-empty>
                    </template>
                </div>
            </div>
            <div class="approval-list-container">
                <div class="approval-list-tip">审批流程</div>

                <div class="approval-list">
                    <div class="name-container active">
                        <span>{{ detail.true_name | sub_name }}<i class="el-icon-success"></i></span>
                        <div class="info-container">
                            <div class="info-top">
                                <div class="info-content">{{ detail.true_name }}</div>
                                <div class="info-time">{{ detail.add_time | formatTime }}</div>
                            </div>
                            <div class="info-desc">发起上游协议用印申请</div>
                        </div>
                    </div>
                    <div v-if="parseFloat(Number(detail.self_amount).toFixed(6)) !== 0" class="name-container active">
                        <span>签字<i :class="detail.sign ? 'el-icon-success' : 'el-icon-remove'"></i></span>
                        <div class="info-container">
                            <div class="info-top">
                                <div class="info-content">企业负责人</div>
                                <div v-if="detail.allSp[0].sp_time" class="info-time">
                                    {{ detail.allSp[0].sp_time | formatTime }}
                                </div>
                            </div>
                            <div class="info-desc">
                                {{ detail.sign ? "已签字" : "签字中" }}
                            </div>
                        </div>
                    </div>
                    <div v-if="detail.state != 6" class="name-container active">
                        <span>{{ detail.shenpi[0].true_name | sub_name }}
                          <i :class="detail.shenpi[0].state === 0 || detail.shenpi[0].state === 3
                                ? 'el-icon-remove'
                                : detail.shenpi[0].state === 1
                                ? 'el-icon-success'
                                : 'el-icon-error' "></i>
                        </span>
                        <div class="info-container">
                            <div class="info-top">
                                <div class="info-content">
                                    {{ detail.shenpi[0].true_name
                                    }}<i v-if="detail.shenpi[0].state != 0"
                                >({{ detail.shenpi[0].state | sp_state(0) }})</i
                                >
                                </div>
                                <div v-if="detail.shenpi[0].sp_time" class="info-time">
                                    {{ detail.shenpi[0].sp_time | formatTime }}
                                </div>
                            </div>
                            <div v-if="detail.shenpi[0].state === 0" class="info-desc">
                                {{ iself(detail.shenpi[0]) ? "我（" : ""
                                }}{{ detail.shenpi[0].state | sp_state(0)
                                }}{{ iself(detail.shenpi[0]) ? "）" : "" }}
                            </div>
                            <div v-if="detail.shenpi[0].state !== 0" class="info-desc">
                                {{ detail.shenpi[0].sp_remark }}
                            </div>
                        </div>
                    </div>
                    <div v-if="detail.shenpi[0].state == 1 && detail.shenpi.length > 3" class="name-container active">
            <span
            >{{
                    detail.shenpi[1].true_name | sub_name
                }}
              <i
                  :class="
                  detail.shenpi[1].state === 0 || detail.shenpi[1].state === 3
                    ? 'el-icon-remove'
                    : detail.shenpi[1].state === 1
                    ? 'el-icon-success'
                    : 'el-icon-error'
                "
              ></i>
            </span>
                        <div class="info-container">
                            <div class="info-top">
                                <div class="info-content">
                                    {{ detail.shenpi[1].true_name
                                    }}<i v-if="detail.shenpi[1].state != 0"
                                >({{ detail.shenpi[1].state | sp_state(1) }})</i
                                >
                                </div>
                                <div v-if="detail.shenpi[1].sp_time" class="info-time">
                                    {{ detail.shenpi[1].sp_time | formatTime }}
                                </div>
                            </div>
                            <div v-if="detail.shenpi[1].state === 0" class="info-desc">
                                {{ iself(detail.shenpi[1]) ? "我（" : ""
                                }}{{ detail.shenpi[1].state | sp_state(1)
                                }}{{ iself(detail.shenpi[1]) ? "）" : "" }}
                            </div>
                            <div v-if="detail.shenpi[1].state !== 0" class="info-desc">
                                {{ detail.shenpi[1].sp_remark }}
                            </div>
                        </div>
                    </div>
                    <div v-if="(detail.shenpi[detail.shenpi.length -3].state == 1 && detail.confirm == 0 )|| detail.confirm ==1"
                         class="name-container active">
                        <span>
                          {{ detail.true_name | sub_name }}
                          <i :class="detail.confirm === 0 ? 'el-icon-remove' : 'el-icon-success'"></i>
                        </span>
                        <div class="info-container">
                            <div class="info-top">
                                <div class="info-content">{{ detail.true_name }}</div>
                                <div v-if="detail.shenpi.length > 3">
                                    <div v-if="detail.allSp[parseFloat(Number(detail.self_amount).toFixed(6)) !== 0 ? 3 : 2].sp_time"
                                         class="info-time">
                                        {{ detail.allSp[parseFloat(Number(detail.self_amount).toFixed(6)) !== 0 ? 3 : 2].sp_time | formatTime
                                        }}
                                    </div>
                                </div>
                                <div v-else>
                                    <div v-if="detail.allSp[parseFloat(Number(detail.self_amount).toFixed(6)) !== 0 ? 2 : 1].sp_time"
                                         class="info-time">
                                        {{ detail.allSp[parseFloat(Number(detail.self_amount).toFixed(6)) !== 0 ? 2 : 1].sp_time | formatTime
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div class="info-desc">
                                {{ detail.confirm === 0 ? "确认中" : "确认知晓" }}
                            </div>
                        </div>
                    </div>
                    <div v-if="detail.confirm ==1">
                        <div v-for="item in detail.shenpi.slice(detail.shenpi.length > 3 ? 2 : 1,detail.shenpi.length > 3 ? 3 : 2)"
                             :key="item.id"
                             class="name-container active">
                            <span>{{ item.true_name | sub_name }}
                              <i :class="item.state === 0? 'el-icon-remove': item.state === 1
                              ? 'el-icon-success' : 'el-icon-error'"></i>
                            </span>
                            <div class="info-container">
                                <div class="info-top">
                                    <div class="info-content">
                                        {{ item.true_name }}
                                    </div>
                                    <div v-if="item.sp_time" class="info-time">
                                        {{ item.sp_time | formatTime }}
                                    </div>
                                </div>
                                <div class="info-desc">
                                    {{ item.state == 1 ? "已用印" :
                                    (item.state == 2 ? "已驳回（" + item.sp_remark + "）" : "用印确认中") }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--<div-->
                    <!--class="name-container active"-->
                    <!--v-if="(detail.shenpi[1].state === 1) && detail.confirm ==1"-->
                    <!--&gt;-->
                    <!--<span>-->
                    <!--{{-->
                    <!--detail.true_name.substr(-->
                    <!--detail.true_name.length - 2,-->
                    <!--detail.true_name.length-->
                    <!--)-->
                    <!--}}-->
                    <!--<i-->
                    <!--:class="-->
                    <!--detail.state !== 2 && detail.state !== 4-->
                    <!--? 'el-icon-remove'-->
                    <!--: 'el-icon-success'-->
                    <!--"-->
                    <!--&gt;</i>-->
                    <!--</span>-->
                    <!--<div class="info-container">-->
                    <!--<div class="info-top">-->
                    <!--<div class="info-content">{{ detail.true_name }}</div>-->
                    <!--&lt;!&ndash;<div class="info-time">{{detail.sp_time | formatTime}}</div>&ndash;&gt;-->
                    <!--</div>-->
                    <!--<div class="info-desc">-->
                    <!--{{-->
                    <!--detail.seal_files.length > 0-->
                    <!--? "已上传盖章协议"-->
                    <!--: detail.state !== 2 && detail.state !== 4-->
                    <!--? "上传盖章协议中"-->
                    <!--: "未上传盖章协议"-->
                    <!--}}-->
                    <!--</div>-->
                    <!--<div-->
                    <!--class="info-desc"-->
                    <!--v-if="detail.seal_files && detail.seal_files.length > 0"-->
                    <!--&gt;-->
                    <!--<div class="upfile-list">-->
                    <!--<li-->
                    <!--class="flex-center between-box"-->
                    <!--v-for="(file, index) in detail.seal_files"-->
                    <!--:key="index"-->
                    <!--&gt;-->
                    <!--<a-->
                    <!--class="ovHide"-->
                    <!--:href="file.filePath | filePath"-->
                    <!--target="_blank"-->
                    <!--&gt;{{ file.fileName }}</a-->
                    <!--&gt;-->
                    <!--</li>-->
                    <!--</div>-->
                    <!--</div>-->
                    <!--<div-->
                    <!--class="info-desc"-->
                    <!--v-if="-->
                    <!--member_type == 1 &&-->
                    <!--detail.seal_files.length === 0 &&-->
                    <!--detail.state === 1-->
                    <!--"-->
                    <!--&gt;-->
                    <!--<div v-if="files.length > 0" class="upfile-list">-->
                    <!--<ul v-for="(file, index) in files" :key="index">-->
                    <!--<li class="flex-center between-box">-->
                    <!--<a-->
                    <!--class="ovHide"-->
                    <!--:href="file.filePath | filePath"-->
                    <!--target="_blank"-->
                    <!--&gt;{{ file.fileName }}</a-->
                    <!--&gt;-->
                    <!--<i class="el-icon-delete" @click="closeFile(index)"></i>-->
                    <!--</li>-->
                    <!--</ul>-->
                    <!--</div>-->
                    <!--&lt;!&ndash;<div class="info-file">{{fileName}}</div>&ndash;&gt;-->
                    <!--<el-upload-->
                    <!--action="http://upload-z2.qiniup.com/"-->
                    <!--:show-file-list="false"-->
                    <!--:multiple="true"-->
                    <!--:data="QiniuData"-->
                    <!--:on-error="uploadError"-->
                    <!--:on-success="uploadSuccess"-->
                    <!--:before-upload="beforeAvatarUpload"-->
                    <!--&gt;-->
                    <!--<el-button class="info-file-up"-->
                    <!--&gt;<i class="el-icon-upload info-file-up-logo"></i-->
                    <!--&gt;点击上传文件-->
                    <!--</el-button-->
                    <!--&gt;-->
                    <!--&lt;!&ndash;<div slot="tip" class="el-upload__tip">只能上传PDF文件</div>&ndash;&gt;-->
                    <!--</el-upload>-->
                    <!--</div>-->
                    <!--</div>-->
                    <!--</div>-->
                    <div v-if="detail.state == 2 && detail.confirm ==1 || detail.state == 4"
                         class="name-container active">
                        <span>{{ detail.shenpi[detail.shenpi.length - 1].true_name | sub_name }}
                          <i :class="
                              detail.state == 3 ||
                              detail.shenpi[detail.shenpi.length -1].state === 0 ||
                              detail.shenpi[detail.shenpi.length -1].state === 3
                                ? 'el-icon-remove'
                                : detail.shenpi[detail.shenpi.length -1].state === 1
                                ? 'el-icon-success'
                                : 'el-icon-error'
                            "></i>
                        </span>
                        <div class="info-container">
                            <div class="info-top">
                                <div class="info-content">
                                    {{ detail.shenpi[detail.shenpi.length - 1].true_name
                                    }}<i v-if="detail.shenpi[detail.shenpi.length -1].state != 0"
                                >({{ detail.shenpi[detail.shenpi.length - 1].state | sp_state(detail.shenpi.length - 1)
                                    }})</i
                                >
                                </div>
                                <div v-if="detail.shenpi[detail.shenpi.length -1].sp_time" class="info-time">
                                    {{ detail.shenpi[detail.shenpi.length - 1].sp_time | formatTime }}
                                </div>
                            </div>
                            <div v-if="detail.shenpi[detail.shenpi.length -1].state === 0" class="info-desc">
                                {{ iself(detail.shenpi[detail.shenpi.length - 1]) ? "我（" : ""
                                }}{{ detail.shenpi[detail.shenpi.length - 1].state | sp_state(detail.shenpi.length - 1)
                                }}{{ iself(detail.shenpi[detail.shenpi.length - 1]) ? "）" : "" }}
                            </div>
                            <div v-if="detail.shenpi[detail.shenpi.length -1].state !== 0" class="info-desc">
                                {{ detail.shenpi[detail.shenpi.length - 1].sp_remark }}
                            </div>
                        </div>
                    </div>
                    <div class="name-container">
                        <span class="iconfont icontongzhi"></span>
                        <div class="info-container">
                            <div class="info-content">抄送人</div>
                            <div class="info-desc">抄送{{ detail.copy.length }}人</div>
                            <div class="copy-members">
                                <div
                                    v-for="item in detail.copy"
                                    :key="item.id"
                                    class="copy-members-name"
                                >
                  <span>{{
                          item.true_name | sub_name
                      }}</span>
                                    <div>{{ item.true_name }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="approval-btn">
                <el-button
                    v-if="member_type == 1 && ( detail.state == 6 || (detail.state == 0 && parseFloat(Number(detail.self_amount).toFixed(6)) === 0))"
                    class="approval-btn-left"
                    @click="toRevoke()">
                    撤回
                </el-button>
                <el-button
                    v-if="detail.state == 4 && isIfVoidApprovaler && detail.if_void == 0"
                    class="approval-btn-left"
                    @click="ifVoidVisible = true">
                    作废
                </el-button>
                <router-link :to="'/home/subSponsor?id=' + pid" v-if="member_type == 1 && detail.state == 3">
                    <el-button
                        class="approval-btn-left">重新提交
                    </el-button>
                </router-link>
                <el-button
                    v-if="(member_type == 0 && detail.confirm === 1 && detail.shenpi[detail.shenpi.length -3].state === 1
                    && detail.shenpi[detail.shenpi.length -2].state === 0  && detail.state === 1 && !isDJApprovaler) ||
                     (detail.state == 1 && member_type == 1 && detail.confirm === 0 && detail.shenpi[detail.shenpi.length -3].state === 1) ||
                     (isApprovaler && member_type == 0 && isDJApprovaler) || (isApprovaler && member_type == 0 && !isDJApprovaler)" class="approval-btn-left"
                    @click="shenpi_rejectedVisible = true">驳回
                </el-button>
                <el-button
                    v-if="detail.state == 1 && member_type == 1 && detail.confirm === 0 && detail.shenpi[detail.shenpi.length -3].state === 1"
                    :disabled="requestState" :loading="requestState"
                    class="approval-btn-right"
                    @click="contractConfirm"
                >已申请线下用印
                </el-button>
                <el-button
                    v-if="member_type == 0 && detail.confirm === 1 && detail.shenpi[detail.shenpi.length -3].state === 1 &&
                    detail.shenpi[detail.shenpi.length -2].state === 0  && detail.state === 1 && !isDJApprovaler"
                    :disabled="requestState" :loading="requestState"
                    class="approval-btn-right"
                    @click="approvalConfirm"
                >已用印
                </el-button>
                <!--                <el-button-->
                <!--                    v-if="member_type == 0 && detail.confirm === 1 && detail.shenpi[detail.shenpi.length -3].state === 1 && detail.shenpi[detail.shenpi.length -2].state === 0  && detail.state === 1"-->
                <!--                    :disabled="requestState" :loading="requestState"-->
                <!--                    class="approval-btn-left"-->
                <!--                    @click="showDialog(2)"-->
                <!--                >用印驳回-->
                <!--                </el-button>-->
                <!--<el-button-->
                <!--class="approval-btn-right"-->
                <!--v-if="member_type == 1 && detail.state == 1 && !detail.file_name && detail.shenpi[1].state === 1 "-->
                <!--@click="subFile()"-->
                <!--&gt;提交-->
                <!--</el-button>-->
                <el-button
                    v-if="isApprovaler && member_type == 0 && !isDJApprovaler"
                    class="approval-btn-left"
                    @click="showDialog(2)">拒绝
                </el-button>
                <el-button
                    v-if="isApprovaler && member_type == 0 && !isDJApprovaler"
                    class="approval-btn-right"
                    @click="showDialog(1)">
                    通过
                </el-button>
                <el-button
                    v-if="isApprovaler && member_type == 0 && isDJApprovaler "
                    :disabled="requestState" :loading="requestState"
                    class="approval-btn-right"
                    @click="cwConfirm">
                    已存档
                </el-button>
            </div>
        </div>
        <div v-if="detail.history.length > 0" class="history-container">
            <div class="history-title">历史记录</div>
            <div class="history-list">
                <router-link
                    v-for="(item, index) in detail.history"
                    :key="index"
                    :to="'/home/sponsorDetail?cctype=1&id=' + item.id + '&type=' + member_type"
                    class="history-item"
                >
                    <div class="item-content">{{ index + 1 }}.{{ item.desc }}</div>
                    <div class="item-time">{{ item.add_time }}</div>
                </router-link>
            </div>
        </div>
        <el-dialog
            :before-close="closeDialog"
            :visible.sync="editVisible"
            center
            width="30%"
        >
      <span slot="title" class="dialog-title">{{
              state === 1 ? "审批同意确认" : "审批拒绝原因"
          }}</span>
            <div class="dialog-container flex-center">
                <el-input
                    v-model="remark"
                    :placeholder="state === 1 ? '请输入审批同意意见' : '请输入审批拒绝原因'"
                    :rows="5"
                    resize="none"
                    type="textarea"
                ></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
        <el-button :disabled="requestState" :loading="requestState" type="primary" @click="toApproval()">确定</el-button>
      </span>
        </el-dialog>
        <el-dialog
            :before-close="closeDialog"
            :visible.sync="ifVoidVisible"
            center
            width="30%">
            <span slot="title" class="dialog-title">作废</span>
            <div class="dialog-container flex-center">
                <el-input
                    v-model="remark"
                    :rows="5"
                    placeholder=" 请输入作废原因"
                    resize="none"
                    type="textarea"
                ></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
        <el-button :disabled="requestState" :loading="requestState" type="primary"
                   @click="agreementToVoid()">确定</el-button>
      </span>
        </el-dialog>
        <el-dialog :before-close="closeDialog" :visible.sync="shenpi_rejectedVisible"
                   center width="30%">
            <span slot="title" class="dialog-title">审批驳回</span>
            <div class="dialog-container flex-center">
                <el-input v-model="remark" placeholder="请输入审批驳回原因"
                          :rows="5" resize="none" type="textarea"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button :disabled="requestState" :loading="requestState" type="primary"
                           @click="shenpi_rejected()">确定</el-button>
              </span>
        </el-dialog>
        <el-tooltip placement="top" effect="light">
            <div slot="content">
                <div v-for="(item,index) in detail.shenpi_rejected" :key="index">
                    {{ item.true_name }}{{ item.create_time | formatTime }}驳回了审批，驳回原因为：{{ item.message }}<br/>
                </div>
            </div>
            <div class="record flex align-center justify-center" v-if="detail.shenpi_rejected && detail.shenpi_rejected.length">
                驳回<br>记录
            </div>
        </el-tooltip>
        <sponsor-print :detail="detail" :dialog-visible="printDialogVisible" @watchState="watchState"></sponsor-print>
    </div>
</template>

<script>
import ElButton from "../../../node_modules/element-ui/packages/button/src/button.vue";
import {genUpToken, qiniuaddr, uploadPath} from "../../tools/CryptoJS";
import config from "../../config/index";
import {getPathIndex, sub_name, webUrl, ZHDate} from "../../tools";
import SponsorPrint from "./print/sponsor";
import ProjectInfo from "../components/ProjectInfo";

export default {
    components: {ProjectInfo, SponsorPrint, ElButton},
    name: "sponsorDetail",
    data() {
        return {
            pid: "",
            member_type: -1,
            isApprovaler: false,
            isDJApprovaler: false,
            isIfVoidApprovaler: false,
            editVisible: false,
            shenpi_rejectedVisible: false,
            ifVoidVisible: false,
            requestState: false,
            state: -1,
            remark: "",
            detail: {},
            QiniuData: {
                fname: "", //  文件名
                key: "", //文件名字处理
                token: "", //七牛云token
            },
            qiniuaddr: qiniuaddr, // 七牛云的图片外链地址 你的七牛云里配置有
            uploadPath: uploadPath, // 七牛云的图片外链地址 你的七牛云里配置有
            files: [],
            printDialogVisible: false,
            isZGH: this.$store.state.user.mid === config.zhennggehong,
            speLists: {
                teshu:[],
                zhaoshang:[],
            },
            speTab:0
        };
    },
    created() {
        this.QiniuData.token = genUpToken();
        this.$emit("setIndex", [0, getPathIndex(this.$route.query.type)]);
        this.init();
    },
    methods: {
        watchState() {
            this.printDialogVisible = false
        },
        showDialog(type) {
            this.editVisible = true;
            this.state = type;
            this.remark = this.state === 1 ? (this.isDJApprovaler ? '已存档' : "同意用印") : "";
        },
        closeDialog() {
            this.remark = ''
            this.shenpi_rejectedVisible = false;
            this.editVisible = false;
            this.ifVoidVisible = false;
            this.state = -1;
        },
        toApproval() {
            if (!this.remark) {
                this.$message.error(
                    this.state === 1 ? "请输入审批同意意见" : "请输入审批拒绝原因"
                );
                return;
            }
            this.requestState = true
            this.$api.apiContent
                .shenpi({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    type: 3, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                    id: this.pid,
                    state: this.state,
                    remark: this.remark,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            type: 3, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                            id: this.pid,
                            state: this.state,
                            remark: this.remark,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.closeDialog();
                    this.$message.success(res.msg);
                    this.init();
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        shenpi_rejected() {
            if (!this.remark) return this.$message.error("请输入驳回原因")
            this.requestState = true
            this.$api.apiContent
                .shenpi_rejected({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    type: 3, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                    state: 2,
                    id: this.pid,
                    remark: this.remark,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            type: 3, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                            state: 2,
                            id: this.pid,
                            remark: this.remark,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.$message.success(res.msg);
                    this.init();
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        toRevoke() {
            this.$api.apiContent
                .revoke({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    type: 3, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                    id: this.pid,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            type: 3, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                            id: this.pid,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.$message.success(res.msg);
                    this.backPage();
                })
                .catch((e) => {
                    this.$message.error(e.msg);
                });
        },
        cwConfirm() {
            this.state = 1;
            this.remark = '已存档'
            this.toApproval()
        },
        approvalConfirm() {
            this.requestState = true
            this.$api.apiContent
                .shenpi({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    type: 3, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                    id: this.pid,
                    state: 1,
                    affirm: 1,//确认传参
                    remark: "同意",
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            type: 3, //审批类型 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                            id: this.pid,
                            state: 1,
                            affirm: 1,//确认传参
                            remark: "同意",
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.$message.success(res.msg);
                    this.init();
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        contractConfirm() {
            this.requestState = true
            this.$api.apiContent
                .zanConfirm({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: this.pid,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.pid,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.$message.success(res.msg);
                    this.init();
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        agreementToVoid() {
            if (!this.remark) {
                this.$message.error("请输入审批作废原因");
                return;
            }
            this.requestState = true
            this.$api.apiContent
                .agreementToVoid({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: this.pid,
                    void_desc: this.remark,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.pid,
                            void_desc: this.remark,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.closeDialog();
                    this.$message.success(res.msg);
                    this.init();
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        subFile() {
            //                if (this.files.length === 0) {
            //                    this.$message.error('请上传文件')
            //                    return
            //                }
            this.requestState = true
            this.$api.apiContent
                .zanzhuFile({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: this.pid,
                    files: JSON.stringify(this.files),
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.pid,
                            files: JSON.stringify(this.files),
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.$message.success(res.msg);
                    this.init();
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        init() {
            this.detail = {}
            this.pid = this.$route.query.id;
            this.member_type = this.$route.query.type;
            this.$api.apiContent
                .getZanzhu({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: this.pid,
                    type: this.$route.query.cctype ? this.$route.query.cctype : "",
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.pid,
                            type: this.$route.query.cctype ? this.$route.query.cctype : "",
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    res.data.history.map((item) => {
                        item.desc = item.desc.replace(/<br>/g, " ");
                        item.add_time = this.$tools.ZHDate(
                            new Date(item.add_time * 1000),
                            1
                        );
                    });
                    res.data.allSp = res.data.shenpi
                    res.data.shenpi = res.data.shenpi.filter(item => {
                        return !item.is_user || item.is_user === 2
                    })
                    res.data.time =
                        this.$tools.ZHDate(new Date(res.data.project.start_time * 1000)) +
                        " 至 " +
                        this.$tools.ZHDate(new Date(res.data.project.end_time * 1000));
                    // res.data.self_amount = Number(res.data.self_amount);
                    res.data.qr_code = res.data.qr_code
                        ? config.urlImgStr + res.data.qr_code
                        : "";
                    res.data.sign = res.data.sign ? config.urlImgStr + res.data.sign : "";
                    this.detail = res.data;
                    this.getUpstreamDataSpecialEndorsement();
                    this.isApproval();
                })
                .catch(() => {
                    this.detail = {};
                });
        },
        getUpstreamDataSpecialEndorsement() {
            this.$api.apiContent
                .getUpstreamDataSpecialEndorsement({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    project_id: this.detail.pid,
                    zs_id: this.detail.zs_id,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            project_id: this.detail.pid,
                            zs_id: this.detail.zs_id,
                        },
                        this.$store.state.user.key
                    ),
                }).then((res) => {
                res.data.teshu.map((item) => {
                    item.add_time = this.$tools.ZHDate(
                        new Date(item.add_time * 1000), 1);
                });
                res.data.zhaoshang.map((item) => {
                    item.add_time = this.$tools.ZHDate(
                        new Date(item.add_time * 1000), 1);
                });
                this.speLists = res.data;
            })
        },
        iself: function (item) {
            return item.user_id === this.$store.state.user.mid;
        },
        isApproval: function () {
            this.isDJApprovaler =
                this.detail.shenpi[this.detail.shenpi.length - 1].user_id === this.$store.state.user.mid;
            this.isIfVoidApprovaler = this.$store.state.user.mid === config.zhangque
            this.editVisible = false;
            this.shenpi_rejectedVisible = false;
            this.isApprovaler = false;
            this.ifVoidVisible = false;
            this.detail.shenpi.map((item, index) => {
                if (item.user_id === this.$store.state.user.mid && item.state === 0) {
                    if (index === 0) {
                        this.isApprovaler = this.detail.state === 0;
                    } else {
                        if (this.detail.shenpi[index - 1].state === 1) {
                            this.isApprovaler = this.detail.state === 2 || (index === 1 && this.detail.shenpi.length > 3);
                        }
                    }
                }
            });
            let that = this
            setTimeout(function () {
                that.requestState = false
            }, 1000)
        },
        //  上传成功
        uploadSuccess(response, file, fileList) {
            console.log(file);
            console.log(fileList);
            this.files.push({
                filePath: `${this.qiniuaddr}/${response.key}`,
                //在这里你就可以获取到上传到七牛的外链URL了
                fileName: file.name,
            });

            this.loading.close();
        },
        closeFile(position) {
            this.$confirm("此操作将删除该文件, 是否继续?", "温馨提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
            })
                .then(() => {
                    this.files.splice(position, 1);
                })
                .catch();
        },
        //  上传失败
        uploadError() {
            this.loading.close();
            this.$message({
                message: "上传出错，请重试！",
                type: "error",
                center: true,
            });
        },
        beforeAvatarUpload(file) {
            //                const isJPG = file.type === 'application/pdf';
            //                if (!isJPG) {
            //                    this.$message.error('只能上传PDF格式!');
            //                    return
            //                }
            //这里的key给加上了时间戳，目的是为了防止上传冲突
            this.QiniuData.fname = file.name;
            this.QiniuData.key = `${new Date().getTime()}${file.name}`;
            this.loading = this.$loading({
                lock: true,
                text: "上传中，请稍后",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
        },
        backPage() {
            this.$tools.goBack();
        },
    },
    filters: {
        filePath(url) {
            return webUrl(url)
        },
        state(type, detail) {
            switch (Number(type)) {
                case 0:
                    return "审批中";
                case 1:
                    return "已用印" + (detail.is_replied === 1 ? "" : "（协议未回）");
                case 2:
                    return "协议存档中";
                case 3:
                    return "已驳回";
                case 4:
                    return "已完成";
                case 6:
                    return "企业负责人签字中";
                default:
                    return "已撤销";
            }
        },
        sp_state(type, position) {
            switch (Number(type)) {
                case 0:
                    return position === 3 ? "存档中" : "审批中";
                case 3:
                    return position === 3 ? "未存档" : "未审批";
                case 1:
                    return position === 3 ? "已完成" : "已审核";
                default:
                    return "已驳回";
            }
        },
        formatTime(time) {
            return ZHDate(new Date(time * 1000), 6)
        },
        sub_name(str) {
            return sub_name(str)
        },
    },
    computed: {
        isSend: function () {
            return this.detail.user_id === this.$store.state.user.mid;
        },
    },
    watch: {
        $route: "init",
    },
};
</script>

<style lang="scss" scoped>
.page-box {
    .dialog-title {
        color: #000;
        font-size: 24px;
    }

    .dialog-footer {
        .el-button {
            width: 235px;
        }
    }
    .company-container {
        border: 1px solid #e1e1e1;
        max-height: 800px;
        display: flex;
        flex-direction: column;
        margin: 20px;
        flex-shrink: 0;
        width: 500px;
        padding: 20px;
        overflow-y: auto;
        .tab {
            color: #000000;
            font-size: 20px;
            cursor: pointer;
            &.active {
                color: #3b77e7;
                font-size: 22px;
            }
        }
        .approval-detail-title {
            margin-top: 16px;
            display: flex;
            flex-direction: row;
            color: #999999;
            width: 100%;

            .approval-detail-content {
                &:hover {
                    color: #3b77e7 !important;
                }
            }

            .approval-detail-tip {
                text-align: right;
            }

            .approval-detail-file {
                display: flex;
                flex-direction: column;

                a {
                    cursor: pointer;
                    margin-bottom: 8px;
                    display: block;
                    color: #3b77e7;

                    &:hover {
                        font-size: 18px;
                    }
                }
            }
        }

        .info-item{
            border: 1px solid #e1e1e1;
            margin-top: 20px;
            padding: 15px;
            .name{
                font-size: 18px;
                color: #333333;
            }
            .remark{
                font-size: 16px;
                color: #666666;
            }
            .file {
                display: flex;
                flex-direction: column;
                a {
                    font-size: 18px;
                    cursor: pointer;
                    margin-bottom: 8px;
                    display: block;
                    color: #3b77e7;

                    &:hover {
                        font-size: 18px;
                    }
                }
            }
        }
    }
    .page-title {
        height: 40px;
        margin-bottom: 10px;

        span {
            font-size: 18px;
            line-height: 36px;
            color: #999999;

            b {
                color: #333;
                font-weight: normal;
            }
        }

        div {
            color: #3b77e7;
            font-size: 20px;
            cursor: pointer;

            i {
                display: block;
                width: 24px;
                height: 24px;
                text-align: center;
                line-height: 24px;
                margin-right: 5px;
                border-radius: 100%;
                color: #fff;
                font-size: 14px;
                background-color: #3b77e7;
            }
        }
    }

    .approval-detail-info {
        position: relative;
        width: 100%;
        background-color: #ffffff;
        border-radius: 6px;
        min-height: 600px;
        padding-bottom: 100px;

        .printing {
            position: absolute;
            top: 20px;
            right: 20px;
        }

        .approval-btn {
            display: flex;
            justify-content: center;
            flex-direction: row;
            width: 50%;
            position: absolute;
            bottom: 60px;
            padding-left: 120px;

            .approval-btn-left {
                width: 180px;
                background: transparent;
                border: 1px solid #3b77e7;
                color: #3b77e7;
            }

            .approval-btn-right {
                width: 180px;
                margin-left: 20px;
                background: #3b77e7;
                color: #ffffff;
            }
        }

        .approval-list-container {
            display: flex;
            flex-direction: row;
            padding: 40px 80px;

            .approval-list {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                margin-left: 12px;

                .name-container {
                    position: relative;
                    flex-grow: 1;
                    display: flex;
                    padding-bottom: 50px;
                    flex-direction: row;

                    &.active {
                        &:before {
                            position: absolute;
                            background: #d3d4d6;
                            width: 1px;
                            top: 0px;
                            bottom: 0px;
                            left: 28px;
                            content: "";
                        }
                    }

                    .info-container {
                        flex-grow: 1;
                        margin-left: 20px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .info-top {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;

                            .info-content {
                                font-size: 20px;
                                color: #333333;

                                i {
                                    font-size: 14px;
                                }
                            }

                            .info-time {
                                font-size: 16px;
                                color: #999999;
                            }
                        }

                        .info-desc {
                            margin-top: 2px;
                            font-size: 16px;
                            color: #999999;

                            .upfile-list {
                                width: 400px;

                                li {
                                    height: 31px;
                                    border-bottom: 1px dashed #ccc;

                                    a {
                                        display: block;
                                        width: 350px;
                                        height: 30px;
                                        line-height: 30px;
                                        color: #3b77e7;
                                    }

                                    .el-icon-delete {
                                        font-size: 16px;
                                        cursor: pointer;

                                        &:hover {
                                            color: #3b77e7;
                                        }
                                    }
                                }
                            }

                            .info-file {
                                color: #3b77e7;
                                margin-top: 12px;
                            }

                            .info-file-up {
                                position: relative;
                                width: 250px;
                                background: transparent;
                                border: 1px solid #3b77e7;
                                font-size: 12px;
                                margin-top: 12px;
                                padding-left: 50px;
                                color: #3b77e7;

                                .info-file-up-logo {
                                    position: absolute;
                                    top: 25%;
                                    left: 20px;
                                }
                            }
                        }

                        .copy-members {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            margin-top: 20px;

                            .copy-members-name {
                                margin-right: 10px;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                            }
                        }
                    }

                    span {
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        width: 56px;
                        height: 56px;
                        border-radius: 56px;
                        border: 1px solid #3b77e7;
                        text-align: center;
                        /*line-height: 24px;*/
                        font-size: 16px;
                        color: #ffffff;
                        background-color: #3b77e7;
                        position: relative;

                        i {
                            position: absolute;
                            bottom: -5px;
                            right: -5px;
                            font-size: 18px;
                            width: 18px;
                            height: 18px;
                            overflow: hidden;

                            &.el-icon-remove {
                                color: #ee9900;
                            }

                            &.el-icon-success {
                                color: #13ce66;
                            }

                            &.el-icon-error {
                                color: #ff0000;
                            }
                        }
                    }
                }
            }

            .approval-list-tip {
                font-size: 18px;
                color: #333333;
                flex-shrink: 1;
                margin-top: 12px;
            }
        }

        .approval-detail-container {
            padding: 40px;
            border-bottom: 1px solid #f0f2f5;

            .approval-detail-title {
                margin-top: 16px;
                display: flex;
                flex-direction: row;
                font-size: 18px;
                color: #999999;
                width: 100%;

                .approval-detail-file {
                    flex: 7;
                    flex-shrink: 1;
                    display: flex;
                    flex-direction: column;

                    a {
                        font-size: 18px;
                        cursor: pointer;
                        margin-bottom: 8px;
                        display: block;
                        color: #3b77e7;

                        &:hover {
                            font-size: 18px;
                        }
                    }
                }

                .approval-detail-tip {
                    flex: 1;
                    min-width: 200px;
                    flex-shrink: 0;
                    text-align: right;

                    &.red {
                        color: red;
                    }
                }

                .approval-detail-tip-length {
                    flex-shrink: 0;
                    min-width: 220px;

                    &.red {
                        color: red;
                    }
                }

                .approval-detail-content {
                    flex: 7;
                    flex-shrink: 1;
                    color: #333333;

                    &.red {
                        color: red;
                    }
                }
            }

            .name-container {
                display: flex;
                flex-direction: row;
                padding-left: 40px;

                .state-container {
                    margin-left: 12px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .content {
                        font-size: 22px;
                        color: #333333;

                        .red {
                            color: red;
                        }
                    }

                    .state {
                        font-size: 16px;
                        margin-top: 6px;
                        color: #13ce66;

                        &.red {
                            color: red;
                        }
                    }
                }

                span {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    width: 65px;
                    height: 65px;
                    border-radius: 65px;
                    text-align: center;
                    cursor: pointer;
                    /*line-height: 24px;*/
                    font-size: 20px;
                    color: #fff;
                    background-color: #3b77e7;
                }
            }
        }
    }
}
</style>
